import axios from "axios";
import Vue from "vue";
// 导出基准地址，原因：其他地方不是通过axios发请求的地方用上基准地址
let postUrl = "";
console.log(process.env.NODE_ENV, "process.env.NODE_ENV");
switch (process.env.NODE_ENV) {
    case "production":
        postUrl = "https://www.kohlerapdigitalhub.com";
        break;
    case "preview_demo":
        postUrl = "https://kohlerap-ref.brandsh.cn/index.php";
        break;
    case "development":
        postUrl = "/api";
        break;
}
const instance = axios.create({
  // axios 的一些配置，baseURL  timeout
  baseURL: postUrl,
  timeout: 3000,
});

instance.interceptors.request.use(
  (config) => {
    // config.headers['Content-Type'] =  'application/json'
    config.headers["Cms-Media-Type"] = "mobile";
    // 拦截业务逻辑
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// res => res.data  取出data数据，将来调用接口的时候直接拿到的就是后台的数据

instance.interceptors.response.use((response) => {
  if (response.data.code !== 0) {
    const { msg, requestID, code } = response.data;
    let errorMsg = msg;
    if (code < 1000 && code > 99) {
      errorMsg = `${msg} - ${requestID}`;
    }
    console.log(errorMsg, "errorMsg");
    return Promise.reject(response.data);
  } else {
    return response.data;
  }
});

// 请求工具函数
export default (url, method, submitData) => {
  // 负责发请求：请求地址，请求方式，提交的数据
  return instance({
    url,
    method,
    [method.toLowerCase() === "get" ? "params" : "data"]: submitData,
  });
};
