import request from "./request.js";

/**
 *
 * 首页-展示列表
 * @returns Promise
 */
export function getExhibitionList({ country, type, year }) {
  return request("/api/exhibition/list", "post", {
    country,
    type,
    year,
  });
}

/**
 *
 * 展示列表筛选
 * @returns
 */
export function getSelectList({ country, type, year }) {
  return request("/api/exhibition/select", "post", {
    country,
    type,
    year,
  });
}

/**
 * 视频分类
 * @returns
 */
export function getVideoClass() {
  return request("/api/exhibition/class", "post");
}

/**
 * 视频列表
 * @returns
 */
export function getVideoList() {
  return request("/api/exhibition/video", "post");
}

/**
 * PDF分类
 * @returns
 */
export function getPdfClass() {
  return request("/api/exhibition/pdfclass", "post");
}

/**
 * PDF列表
 * @returns
 */
export function getPdfList() {
  return request("/api/exhibition/pdflist", "post");
}
